
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as change_45passwordX8ivx1vU2lMeta } from "/app/pages/auth/change-password.vue?macro=true";
import { default as indexaVPDnhf1OvMeta } from "/app/pages/auth/index.vue?macro=true";
import { default as auth7tE1sZcZDUMeta } from "/app/pages/auth.vue?macro=true";
import { default as aboutnGGe82ZLhXMeta } from "/app/pages/index/about.vue?macro=true";
import { default as _91slug_93EZ1Xz85okZMeta } from "/app/pages/index/blog/[slug].vue?macro=true";
import { default as indexllF7s3KxDgMeta } from "/app/pages/index/blog/index.vue?macro=true";
import { default as blogfzfR60KsaDMeta } from "/app/pages/index/blog.vue?macro=true";
import { default as cataloghkMyb8UUU4Meta } from "/app/pages/index/catalog.vue?macro=true";
import { default as contactsz1fQ6e3a2BMeta } from "/app/pages/index/contacts.vue?macro=true";
import { default as delivery_45and_45paymentZEcBSnT7oqMeta } from "/app/pages/index/delivery-and-payment.vue?macro=true";
import { default as faqtf6EVCpyWxMeta } from "/app/pages/index/faq.vue?macro=true";
import { default as index3J5r3Sf7qAMeta } from "/app/pages/index/index.vue?macro=true";
import { default as legalqBE5txWhZaMeta } from "/app/pages/index/legal.vue?macro=true";
import { default as index7vtlxWa4MWMeta } from "/app/pages/index/order/index.vue?macro=true";
import { default as successfulPAWfBRROrpMeta } from "/app/pages/index/order/successful.vue?macro=true";
import { default as _91slug_93uprPRV9i3iMeta } from "/app/pages/index/product/[slug].vue?macro=true";
import { default as profileZxBGWFYWGgMeta } from "/app/pages/index/profile.vue?macro=true";
import { default as public_45offersQCpyBg7YbMeta } from "/app/pages/index/public-offer.vue?macro=true";
import { default as social_45responsibilityZP7N5fIl22Meta } from "/app/pages/index/social-responsibility.vue?macro=true";
import { default as subscribe_45product2MtBje472HMeta } from "/app/pages/index/subscribe-product.vue?macro=true";
import { default as sustainablen6OmLmvrwYMeta } from "/app/pages/index/sustainable.vue?macro=true";
import { default as vacanciessWf8nRup48Meta } from "/app/pages/index/vacancies.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as component_45stubMtdtCSgzW4Meta } from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubMtdtCSgzW4 } from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: auth7tE1sZcZDUMeta?.name,
    path: "/auth",
    component: () => import("/app/pages/auth.vue"),
    children: [
  {
    name: "auth-change-password___ru",
    path: "change-password",
    component: () => import("/app/pages/auth/change-password.vue")
  },
  {
    name: "auth___ru",
    path: "",
    component: () => import("/app/pages/auth/index.vue")
  }
]
  },
  {
    name: indexN6pT4Un8hYMeta?.name,
    path: "/",
    component: () => import("/app/pages/index.vue"),
    children: [
  {
    name: "index-about___ru",
    path: "about",
    component: () => import("/app/pages/index/about.vue")
  },
  {
    name: blogfzfR60KsaDMeta?.name,
    path: "blog",
    component: () => import("/app/pages/index/blog.vue"),
    children: [
  {
    name: "index-blog-slug___ru",
    path: ":slug()",
    component: () => import("/app/pages/index/blog/[slug].vue")
  },
  {
    name: "index-blog___ru",
    path: "",
    component: () => import("/app/pages/index/blog/index.vue")
  }
]
  },
  {
    name: "index-catalog___ru",
    path: "catalog",
    component: () => import("/app/pages/index/catalog.vue")
  },
  {
    name: "index-contacts___ru",
    path: "contacts",
    component: () => import("/app/pages/index/contacts.vue")
  },
  {
    name: "index-delivery-and-payment___ru",
    path: "delivery-and-payment",
    component: () => import("/app/pages/index/delivery-and-payment.vue")
  },
  {
    name: "index-faq___ru",
    path: "faq",
    component: () => import("/app/pages/index/faq.vue")
  },
  {
    name: "index___ru",
    path: "",
    component: () => import("/app/pages/index/index.vue")
  },
  {
    name: "index-legal___ru",
    path: "legal",
    component: () => import("/app/pages/index/legal.vue")
  },
  {
    name: "index-order___ru",
    path: "order",
    component: () => import("/app/pages/index/order/index.vue")
  },
  {
    name: "index-order-successful___ru",
    path: "order/successful",
    component: () => import("/app/pages/index/order/successful.vue")
  },
  {
    name: "index-product-slug___ru",
    path: "product/:slug()",
    component: () => import("/app/pages/index/product/[slug].vue")
  },
  {
    name: "index-profile___ru",
    path: "profile",
    meta: profileZxBGWFYWGgMeta || {},
    component: () => import("/app/pages/index/profile.vue")
  },
  {
    name: "index-public-offer___ru",
    path: "public-offer",
    component: () => import("/app/pages/index/public-offer.vue")
  },
  {
    name: "index-social-responsibility___ru",
    path: "social-responsibility",
    component: () => import("/app/pages/index/social-responsibility.vue")
  },
  {
    name: "index-subscribe-product___ru",
    path: "subscribe-product",
    component: () => import("/app/pages/index/subscribe-product.vue")
  },
  {
    name: "index-sustainable___ru",
    path: "sustainable",
    component: () => import("/app/pages/index/sustainable.vue")
  },
  {
    name: "index-vacancies___ru",
    path: "vacancies",
    component: () => import("/app/pages/index/vacancies.vue")
  }
]
  },
  {
    name: component_45stubMtdtCSgzW4Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubMtdtCSgzW4
  }
]