
import * as ipxRuntime$U9U8EZy8J7 from '/app/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.3_@libsql+client@0.14.0_better-sqlite3@11.8.1__ioredis@5.4.2_magicast@0.3.5_rollup@4.32.1/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxRuntime$glkc5F3klM from '/app/image-provider.ts'

export const imageOptions = {
  "screens": {
    "xs": 375,
    "sm": 768,
    "md": 1024,
    "lg": 1240,
    "xl": 1440,
    "xxl": 1920,
    "2xl": 1536,
    "xxxl": 2560
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$U9U8EZy8J7, defaults: {"maxAge":31536000} },
  ['ipx']: { provider: ipxRuntime$glkc5F3klM, defaults: undefined }
}
        