import payload_plugin_e81h812gyu from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_ENguld79bX from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0iKA1WiA5n from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2WLPGTkXMr from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Lki5viKOgR from "/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.13.1_jiti@2.4._nj62v4fv4cpfdukzpc2xfkp6du/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_1haQcqTBUx from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yZYqBzAz0d from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5Efntu5R0c from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5qOZS91urR from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_76k3KHBGNm from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_QU2LVaxt8p from "/app/node_modules/.pnpm/nuxt@3.15.4_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11_yroddw4jvzhtdlsuxpdqfhxxxm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_l5DU6C1dNH from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2_dn6lpsl4whsscrk7tjrp5ix4pu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import switch_locale_path_ssr_xYE9E36uf4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_4qsy47aaewlqg6skasl3n7a66i/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_dA5dO7Mf5a from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_4qsy47aaewlqg6skasl3n7a66i/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_qNnfrLl2oi from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_4qsy47aaewlqg6skasl3n7a66i/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_7voLNJf0OL from "/app/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.3_@libsql+client@0.14.0_better-sqlite3@11.8.1__ioredis@5.4.2_magicast@0.3.5_rollup@4.32.1/node_modules/@nuxt/image/dist/runtime/plugin.js";
import autosize_N8mFgrWbV7 from "/app/plugins/autosize.ts";
import request_8cwBTcUfTa from "/app/plugins/request.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import ssg_detect_Ar5US62rkQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_4qsy47aaewlqg6skasl3n7a66i/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_e81h812gyu,
  revive_payload_client_ENguld79bX,
  unhead_0iKA1WiA5n,
  router_2WLPGTkXMr,
  _0_siteConfig_Lki5viKOgR,
  payload_client_1haQcqTBUx,
  navigation_repaint_client_yZYqBzAz0d,
  check_outdated_build_client_5Efntu5R0c,
  chunk_reload_client_5qOZS91urR,
  plugin_vue3_76k3KHBGNm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QU2LVaxt8p,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_l5DU6C1dNH,
  switch_locale_path_ssr_xYE9E36uf4,
  route_locale_detect_dA5dO7Mf5a,
  i18n_qNnfrLl2oi,
  plugin_7voLNJf0OL,
  autosize_N8mFgrWbV7,
  request_8cwBTcUfTa,
  sentry_client_shVUlIjFLk,
  ssg_detect_Ar5US62rkQ
]